import { lazy } from 'react';
import Loadable from './Loadable';
import { GuestGuard } from 'components/auth';

// AUTHENTICATION RELATED PAGES
const LoginPage = Loadable(lazy(() => import('pages/auth/login')));
const RegisterPage = Loadable(lazy(() => import('pages/auth/register')));
const VerifyEmailPage = Loadable(lazy(() => import('pages/auth/verifyEmail')));

export const AuthRoutes = [
{
    element: <GuestGuard />,
    children: [
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'register',
        element: <RegisterPage />
      },
      {
        path: 'verify-email/:token?',
        element: <VerifyEmailPage />
      },

  ]
},

];